import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   redirect: '/dashboard/home'
  // },

  {
    path: '/',
    name: "onboard_ui",
 
    component: () => import('@/views/Onboard.vue')
  },
  {
    path: '/get-access',
    name: "get_access_ui",
 
    component: () => import('@/views/GetAccess.vue')
  },
  {
    path: '/dashboard/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/dashboard/home'
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue')
      },
      {
        path: 'tutorial',
        component: () => import('@/views/TutorialPage.vue')
      },
      {
        path: 'booking',
        component: () => import('@/views/BookingPage.vue')
      },
      {
        path: 'message',
        component: () => import('@/views/MessagePage.vue')
      },
      {
        path: 'winnings',
        component: () => import('@/views/WinningsPage.vue')
      },
      {
        path: 'help',
        component: () => import('@/views/HelpPage.vue')
      },
      {
        path: 'code-for-the-day',
        component: () => import('@/views/CodeForTheDay.vue')
      },
      {
        path: 'intl-subscribe-for-the-day',
        component: () => import('@/views/IntlSubscribeForTheDay.vue')
      },
      {
        path: 'subscribe-for-the-day',
        component: () => import('@/views/SubscribeForTheDay.vue')
      },
      {
        path: 'auto-bet',
        component: () => import('@/views/AutoBet.vue')
      },
      {
        path: 'bet-tips-for-the-day',
        component: () => import('@/views/BetTipsForTheDay.vue')
      },
      {
        path: 'payment-for-the-day',
        component: () => import('@/views/PaymentForTheDay.vue')
      },
      {
        path: 'message-for-the-day',
        component: () => import('@/views/MessageForTheDay.vue')
      },
      {
        path: 'winning-for-the-day',
        component: () => import('@/views/WinForTheDay.vue')
      },
      {
        path: 'start',
        component: () => import('@/views/StartPage.vue')
      },
      {
        path: 'disclaimer',
        component: () => import('@/views/DisclaimerPage.vue')
      },
      {
        path: 'subscribe',
        component: () => import('@/views/SubscribePage.vue')
      },
      {
        path: 'configure',
        component: () => import('@/views/ConfigurePage.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
