import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
// Added by the CLI
//import './registerServiceWorker';


import { IonicVue, IonContent, IonButton, IonPage, IonSlide, IonSlides, IonIcon, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonRow, IonChip, IonSegment, IonRefresher, IonSegmentButton, IonTabs, IonRouterOutlet, IonApp, IonTabBar, IonText, IonItem, IonLabel, IonProgressBar, IonToolbar, IonSelect, IonSelectOption, IonList, IonHeader, IonTitle, IonAvatar, IonSpinner } from '@ionic/vue';

// import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue)
  .use(router);
  //:register components globally
app.component('ion-content', IonContent);
app.component('ion-page', IonPage);
app.component('ion-button', IonButton);
app.component('ion-slides', IonSlides);
app.component('ion-slide', IonSlide);
app.component('ion-icon', IonIcon);
app.component('ion-card', IonCard);
app.component('ion-card-content', IonCardContent);
app.component('ion-card-title', IonCardTitle);
app.component('ion-col', IonCol);
app.component('ion-row', IonRow);
app.component('ion-chip', IonChip);
app.component('ion-segment', IonSegment);
app.component('ion-card-header', IonCardHeader);
app.component('ion-refresher', IonRefresher);
app.component('ion-segment-button', IonSegmentButton);
app.component('ion-tabs', IonTabs);
app.component('ion-router-outlet', IonRouterOutlet);
app.component('ion-app', IonApp);
app.component('ion-tab-bar', IonTabBar);
app.component('ion-text', IonText);
app.component('ion-item', IonItem);
app.component('ion-label', IonLabel);
app.component('ion-progress-bar', IonProgressBar);
app.component('ion-toolbar', IonToolbar);
app.component('ion-select', IonSelect);
app.component('ion-select-option', IonSelectOption);
app.component('ion-list', IonList);
app.component('ion-header', IonHeader);
app.component('ion-toolbar', IonToolbar);
app.component('ion-title', IonTitle);
app.component('ion-avatar', IonAvatar);
app.component('ion-spinner', IonSpinner);


  
router.isReady().then(() => {
  app.mount('#app');
});
